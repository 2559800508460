$(document).scroll(function () {
  var scroll = $(this).scrollTop()
    if ($(window).width() >= 1024 && scroll > 680) {
      $('.navbar.nav').addClass('fixed');
    } else {
      $('.navbar.nav').removeClass('fixed');
  };
});
  
$(document).ready(function(){
  $('.navbar-toggler').on('click', function(){
    $('.bg-nav').toggleClass('open');
  });
    
  $('.navbar-nav li a').click(function () {
    $('.navbar-collapse').collapse('hide')
    $('.bg-nav').removeClass('open');
  })
});

$(document).ready(function () {
  // Add class 'active' to section when near top of viewport
  var allSections = document.querySelectorAll('section');
  var steps = document.querySelectorAll('.steps');
  var outro = document.querySelectorAll('.block.outro');

  window.onscroll = function() {
    allSections.forEach(section => {
      if(section.getBoundingClientRect().top <= 600){
        section.classList.add('active');
      }
    });
    
    steps.forEach(section => {
      if(section.getBoundingClientRect().top <= 570){
        section.classList.add('active');
      }
    });
    
    outro.forEach(section => {
      if(section.getBoundingClientRect().top <= 960){
        section.classList.add('animate');
      }
    })
  }
})


$(window).scroll(function (){
  function recthit(rectone, recttwo){

    var r1 = $(rectone);
    var r2 = $(recttwo);
    
    var r1x = r1.offset().left;
    var r1w = r1.width();
    var r1y = r1.offset().top;
    var r1h = r1.height();
    
    var r2x = r2.offset().left;
    var r2w = r2.width();
    var r2y = r2.offset().top;
    var r2h = r2.height();
    
    if( r1y+r1h < r2y ||
        r1y > r2y+r2h ||
        r1x > r2x+r2w ||
        r1x+r1w < r2x ) {
        return false;
    } else {
        return true;   
    }
  }
    
  $('.highlight').each(function(){
    if(recthit('#fixed-orange',$(this))){
      $(this).addClass('collide');
    } 
    
    else if(recthit('#fixed-lightBlue',$(this))){
      $(this).addClass('collide');
    }  
    // else {
    //   $(this).removeClass('collide');
    // }
  });
})

$(window).scroll(function () {
  var position = window.pageYOffset;
  $('.block').each(function () {
    var target = $(this).offset().top;
    var id = $(this).attr('id');

    var navLink = $('.navbar-nav li a');
    if (position >= target - 190 && id) {
      navLink.removeClass('active');
      $('.navbar-nav li a[href*="#' + id + '"]').addClass('active');
    } else if ($(window).scrollTop() === 0 || $(window).scrollTop() < 300) {
      navLink.removeClass('active');
    }
  });
});
 
$(document).on('click', 'a[href^="#"]', function(e) {
  var id = $(this).attr('href');
  var $id = $(id);
  if ($id.length === 0) {
      return;
  }

  e.preventDefault();

  var pos = $id.offset().top -100;

  if ($(window).width() < 960) {
      var pos = $id.offset().top - 80;
  }

  // animated top scrolling
  $('body, html').animate({scrollTop: pos});
});